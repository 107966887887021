import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import styles from "./index.module.scss";

export const query = graphql`
  query BookmarkQuery {
    allBookmark(sort: { fields: site }) {
      group(field: category) {
        nodes {
          site
          url
        }
        fieldValue
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [query, setQuery] = useState("");
  const search = useRef(null);

  const focusHandler = ({ key }) => {
    if (key === " ") {
      search.current.focus();
    }
  };

  // We need to handle escape on keydown instead of on keypress
  const defocusHandler = ({ key }) => {
    if (key === "Escape") {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", focusHandler, false);
    document.addEventListener("keydown", defocusHandler, false);

    return () => {
      document.removeEventListener("keypress", focusHandler, false);
      document.removeEventListener("keydown", defocusHandler, false);
    };
  }, []);

  return (
    <Layout>
      <div className={styles.grid_container}>
        <Helmet>
          <title>/home/will</title>
        </Helmet>
        <h1 id={styles.title}>Bookmarks</h1>
        <input
          id={styles.search}
          ref={search}
          aria-label="Filter bookmarks"
          onChange={e => setQuery(e.target.value)}
        />
        {data.allBookmark.group.flatMap(
          ({ fieldValue: category, nodes: sites }) => {
            const filteredSites = sites.filter(
              ({ site }) => category.includes(query) || site.includes(query)
            );
            return filteredSites.length === 0
              ? []
              : [
                  <div className={styles.category} key={category}>
                    <h3>{category}</h3>
                    {filteredSites.map(({ url, site }) => (
                      <p key={site}>
                        <a href={url} rel="noreferrer noopener" target="_blank">
                          {site}
                        </a>
                      </p>
                    ))}
                  </div>,
                ];
          }
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;
